body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
ol,
ul,
li {
  list-style: none;
}
body {
  background-color: #3e2411;
}
body .loading-content {
  display: none;
}
body.loading {
  background-image: url(https://ant-static.gtfund.com/static/alipay-mini-app-assets/golden/webview/static/Images/loading_bg.jpg);
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-color: #ffffff;
  position: relative;
}
body.loading .loading-content {
  width: 5.8rem;
  height: 5.4rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
}
body.loading .loading-content .golden {
  display: block;
  background-image: url(https://ant-static.gtfund.com/static/alipay-mini-app-assets/golden/webview/static/Images/loading_golden.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 1.86rem;
  height: 1.84rem;
}
body.loading .loading-content .house {
  display: block;
  background-image: url(https://ant-static.gtfund.com/static/alipay-mini-app-assets/golden/webview/static/Images/loading_house.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 5.61rem;
  height: 2.64rem;
}
body.loading .loading-content .bar {
  position: relative;
  width: 5.7rem;
  height: 1.2rem;
}
body.loading .loading-content .bar-bg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  height: 0.4rem;
  width: 100%;
  border-radius: 0.2rem;
  background: linear-gradient(#ffb337, #ff812c);
  border: 1px solid #ffb336;
}
body.loading .loading-content .bar-active {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  height: 0.4rem;
  width: 0;
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
  background-image: linear-gradient(to right, #fec43c, #fdf664);
}
body.loading .loading-content .bar-car {
  display: block;
  background-image: url(https://ant-static.gtfund.com/static/alipay-mini-app-assets/golden/webview/static/Images/loading_car.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 1.2rem;
  height: 0.9rem;
  left: 0;
}
body.loading .loading-content .bar-progress {
  font-size: 0.4rem;
  text-align: center;
  color: #ff6633;
}
.animate-bounce {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-name: bounce-up-down;
  animation-name: bounce-up-down;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
@-webkit-keyframes bounce-up-down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.3rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes bounce-up-down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.3rem);
  }
  100% {
    transform: translateY(0);
  }
}
